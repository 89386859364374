// LoginForm.js
import React, { useState } from 'react';
import axios from 'axios';
import Profile from './Profile'; // Import the Profile component
import '../App.css'

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null); // State to hold user data

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error message
    if (!email || !password) {
      setError('Please enter email and password');
      return;
    }
    try {
      const response = await axios.post('https://dealzup.in/api/v1/login', {
        email,
        password
      });
      const userData = response.data.data.user;
      setUser(userData); // Set user data in state
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  if (user) {
    return <Profile user={user} />; // Render Profile component with user data
  }

  return (


   
      <div class="container mt-5 transparent-box" >
        <div class="row justify-content-center">
          <div class="col-md-6">
            <h2 class="mb-4">Login</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input type="email" placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                <input type="password" placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required className="form-control" id="exampleInputPassword1" />
              </div>

              <button type="submit" className="btn custom_button">Submit</button>
              { error && <p style={{ color: 'red' }}>{error}</p>} {/* Show error message */ }
            </form>
          </div>
        </div>
      </div>
        
  );
}

export default LoginForm;

// // LoginForm.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import Profile from './Profile'; // Import the Profile component

// function LoginForm() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [user, setUser] = useState(null); // State to hold user data

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('https://dealzup.in/api/v1/login', {
//         email,
//         password
//       });
//       const userData = response.data.data.user;
//       setUser(userData); // Set user data in state
//     } catch (error) {
//       setError('Invalid email or password');
//     }
//   };

//   if (user) {
//     return <Profile user={user} />; // Render Profile component with user data
//   }

//   return (
//     <div>
//       <h2>Login</h2>
//       {error && <p>{error}</p>}
//       <form onSubmit={handleSubmit}>
//         <input 
//           type="email" 
//           placeholder="Email" 
//           value={email} 
//           onChange={(e) => setEmail(e.target.value)} 
//           required 
//         />
//         <input 
//           type="password" 
//           placeholder="Password" 
//           value={password} 
//           onChange={(e) => setPassword(e.target.value)} 
//           required 
//         />
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// }

// export default LoginForm;
