// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Profile from './components/Profile';
import Navbar from './components/Navbar';

const App = () => {
  const [token, setToken] = useState(null);
  const [mode, setMode]  = useState('light'); // whether dark mode is enabled or not.
  //we cannot use = sign in state mode.
  const toggleMode = ()=>{
    if(mode === 'light'){
      setMode('dark')
      // document.body.style.backgroundColor="#212529";
      document.body.style.backgroundColor="#343a40";
      document.body.style.color="white";
    }else{
      setMode('light')
      document.body.style.backgroundColor="white";
      document.body.style.color="black";
      document.title = 'DealzUp Login';

    }
  }

  return (
    <>
    <Navbar services="Services" aboutText="About us"  mode={mode} toggleMode={toggleMode} />
    <Router>
      <div>
        <Routes>
          <Route path="/" element={token ? <Profile token={token} /> : <Login setToken={setToken} />} />
          <Route path="/profile" element={token ? <Profile token={token} /> : <Login setToken={setToken} />} />
        </Routes>
      </div>
    </Router>
    </>
  );
};

export default App;
