// Profile.js
import React from 'react';

function Profile({ user }) {
  return (
    <div class="container mt-5 transparent-box" >
        <div class="row justify-content-center">
          <div class="col-md-6">
            <h2>User Profile</h2>
            <p>Name: {user.name}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
            {/* Add more user data fields as needed */}
          </div>
        </div>
      </div>
  );
}

export default Profile;
